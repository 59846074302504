// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
@import "./variables";

// CUSTOM_LOADER:

@keyframes site-preloader-animation {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
}

body {
    overflow: hidden !important;
    overflow-y: scroll !important;
    height: 100% !important;
}

.site-preloader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff6b;
    z-index: 99999;
}

.site-preloader::before {
    box-sizing: border-box;
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-top-color: rgba(0, 0, 0, 0.6);

    animation-name: site-preloader-animation;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.site-preloader__fade {
    transition: opacity 0.3s;
    opacity: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

// CUSTOM LOADER END

.text-color-white {
    color: $light-text-color !important;
}

.error-field {
    color: red;
}

.place-order-section {
    margin: auto;
}

.link-color-custom a {
    color: #fff !important;
}

.text-error {
    color: red;
}

.selected-success {
    border-color: #000000;
}

.text-color-primary {
    color: #000000 !important;
}

.order-status-nav {
    padding: 16px;
}

.text-color-primary.active {
    // color: #5c6b73 !important;
    color: #dc242b !important;
}

.order-table {
    text-align: center;
}

.account-menu-custom {
    overflow-x: hidden;
}

.account-menu__user-custom {
    display: contents;
    padding-top: 0px;
    padding-bottom: 0px;
}

.icon-collapse {
    float: left;
    margin-top: 3px;
    margin-right: 10px;
}

.footer-newsletter__title {
    // margin-top: 20px;
}

.text-align-footer {
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.site-footer__widgets {
    padding-top: 10px;
}

.footer-newsletter__title {
    // text-align: center;
}

.footer-newsletter__form {
    margin: auto;
}

.footer-newsletter__form-button {
    color: #ffffff !important;
    background-color: #dc242b !important;
}

.footer-custom-class {
    width: 100%;
    text-align: center;
}

.error-field-border {
    border: 1px solid red;
}

.addresses-list__item {
    margin-left: 10px;
}

.loading-custom {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    // background: #00000094;
    height: 100%;
    z-index: 999999999;
}

.loading-custom-inner {
    color: white;
    margin-top: 15%;
}

.collapsing {
    height: 0px !important;
    transition: ease-in;
    overflow: scroll;
    position: absolute;
    // background-color: red;
}

.topbar__item-custom {
    margin: 10px;
}

.custom-banner-div {
    background-size: cover;
    background-position: bottom;
}

.orange-color {
    color: #dc242b !important;
}

.white-color {
    color: #ffffff !important;
}

.icon-margin-top {
    margin-right: 4px;
}

.no-orders {
    font-size: 25px;
    margin-left: 15px;
    margin-top: 15px;
}

.rad-check {
    background-color: #dc242b !important;
}

.video-class {
    text-align: center;
}

.mega-capitalize {
    text-transform: capitalize;
}

.block-products-carousel {
    padding: 100;
}

.category-card__image {
    width: 100%;
}

.block-categories__item_custom {
}

.block-categories__list_custom {
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    margin: -6px;
}

.block-header {
    justify-content: center;
    padding: 50px;
}

.block-header__arrows-list {
    justify-content: flex-end;
    position: absolute;
    right: 0;
    margin-right: 5%;
}

.block-catalog__list_custom {
    // width: 90%;
    margin: auto;
    justify-content: center;
}

.category-list-item-custom {
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #ffffff;
    margin: 10px;
    border-radius: 5px;
}

.category-list-item-custom img {
    width: 100%;
    padding: 15% 10% 10% 10%;
}

.category-list-item-custom a {
    color: #000000;
    font-weight: 500;
}

.category-list-item-custom .custom-txt {
    padding-top: 50px;
    padding-bottom: 20px;
}

.block-categories__item_custom {
    text-align: center;
}

.block-header {
    position: relative;
    display: block;
}

.block-header h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 26px;
    letter-spacing: 1px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 16px 0;
    grid-gap: 22px;
}

.block-header h3:after,
.block-header h3:before {
    content: " ";
    display: block;
    border-bottom: 2px solid #ccc;
}

.social-links__link--type--linkedin-square {
    background-color: #0e76a8;
}

.color-red {
    color: #dc242b;
}

.custom-topbar {
    text-align: left;
    padding: 10px 40px 10px 20px;
    color: #ffffff;
    background: #ecb134;
    flex-direction: row;
}

.custom-topbar-inner {
    flex-direction: row;
}

@media (max-width: 767px) {
    .block-banner__body {
        height: 150px;
    }
}

.discalimer-txt {
    margin: 10px;
    color: red;
    margin-left: 0;
}

.wa-btn-float {
    position: fixed;
    bottom: 100px;
    right: 25px;
    // color: white;
    // // background-color: #25D366;
    // width: 50px;
    // height: 50px;
    // border-radius: 150px;
}

.wa-image {
    width: 50px;
    height: 50px;
}

.wa-link {
    width: 50px;
    height: 50px;
}

.top-cart {
    float: left;
    position: absolute;
    right: 130px;
}
.mobile-menu-selected {
    // background: #dc242b;
    // color: #ffffff;
}

#home-video-mobile {
    display: none;
}

@media only screen and (max-width: 500px) {
    #home-video-mobile {
        display: block;
    }
    #home-video {
        display: none;
    }
}

.collapse-custom {
    float: left;
    margin-right: 10px;
}
.faq-h6 {
    margin-left: 20px;
}

// ABOUT US CSS ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

.inner_banner_bg {
    // background-image: url(../images/about_bg.jpg);
    background-size: 100% 100%;
    padding: 15% 0 5% 0;
    float: left;
    width: 100%;
}

.inner_banner_bg h1 {
    display: inline-block;
    background: #000000a6;
    color: #fff;
    text-transform: uppercase;
    float: right;
    font-size: 30px;
    padding: 25px 25px;
    text-align: center;
}

.grey_dark_area {
    background: #222528;
    float: left;
    width: 100%;
    color: #fff;
    text-align: center;
    padding: 60px 0px;
}

.grey_dark_area h2 {
    font-size: 30px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-transform: uppercase;
}

.products_bg {
    // background-image: url(../images/products_bg.jpg);
    background-size: 100% 100%;
    padding: 50px 0px;
    float: left;
    width: 100%;
    text-align: center;
}

.products_bg p {
    color: #343434;
    font-size: 18px;
}

.pop_cat {
    display: inline-block;
    background: #3f4c6b;
    color: #fff;
    border-radius: 20px;
    padding: 16px 30px;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 40px;
}

.products_bg h2 {
    font-size: 36px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 30px;
}

.pop_cir {
    text-align: center;
}

.pop_cir img {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}

.pop_cir a {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    text-decoration: none;
}

.bg_l1 {
    background-color: #ebebeb;
    color: #000;
}

.bg_l1 h2 {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.custom-block-1 {
    padding: 82px;
    text-align: left;
}

.bg_r1 {
    background-color: #ffffff;
    color: #000;
}

.bg_r1 h2 {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.custom-block-2 {
    padding: 82px 52px;
    text-align: left;
    padding-bottom: 0;
}

.grey_dark_area2 {
    // background-image: url(../images/grey_dark_area2.jpg);
    background-size: 100% 100%;
    float: left;
    width: 100%;
    color: #fff;
    padding: 40px 0px;
}

.grey_dark_area2 p {
    font-size: 18px;
}

.con_btn {
    background-color: rgb(255, 255, 255);
    width: 178px;
    height: 53px;
    float: right;
    border: 0px;
    font-size: 18px;
    font-weight: 500;
}

@media (max-width: 1192px) {
    .custom-block-2 {
        padding: 25px 25px;
    }
}

@media (max-width: 992px) {
    .custom-block-1 {
        padding: 25px;
    }

    .custom-block-2 {
        padding: 25px;
    }

    .con_btn {
        float: none;
    }
    .block-banner__body {
        height: 200px;
    }
}

@media (max-width: 767.98px) {
    .custom-block-1 {
        padding: 35px 0 0 0;
    }

    .custom-block-2 {
        padding: 35px 0 15px 0;
    }
}

// ABOUT US CSS END---------------------------------------------------------------------------------------------------------------------------------------------------------------------

// tangorx NEW CSS START---------------------------------------------------------------------------------------------------------------------------------------------------------------------
.block-banner__body {
    height: 314px;
}
.topbar__item-custom {
}
.topbar__row-custom {
    width: 80%;
    margin: auto;
}

.nav-panel {
    background: #050721 !important;
    color: #ffffff !important;
}

.block-header {
    padding-top: 0px !important;
}

.account-menu__user {
    justify-content: flex-start;
    text-align: left;
}
.account-menu__links {
    justify-content: flex-start;
    text-align: left;
}
.account-menu__links {
    justify-content: flex-start;
    text-align: left;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .block-slideshow--layout--full .block-slideshow__slide-image--desktop {
        background-position: center;
    }
}

// .block-slideshow--layout--full .block-slideshow__slide-image--desktop {
//     background-position: center;
// }
// .block-banner__body {
//     height: 314px;
// }
// .block-banner__image {
//     background-size: 100%;
// }
// .block-slideshow--layout--full .block-slideshow__slide-image--desktop {
//     background-position: center;
// }
// @media (max-width: 1192px) {
//     .block-banner__body {
//         height: 150px;
//     }
// }

@media (min-width: 768px) and (max-width: 991px) {
    .block-slideshow--layout--full .block-slideshow__slide-image--desktop {
        background-position: center;
    }
}
.address-card__body {
    text-align: center;
}
@media (max-width: 767px) {
    .block-slideshow__body,
    .block-slideshow__slide {
        height: 290px !important;
    }
    .block-slideshow__slide-image--mobile {
        background-size: 100% !important;
    }
    .products-list[data-layout="grid-4-full"] .products-list__item {
        width: 46%;
        margin: 6px 6px;
        float: left;
    }
    .product-card__name a {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        min-height: 40px;
        max-height: 40px;
    }
}

.block-banner__image {
    background-size: 100%;
}
.social-links__list {
    margin: auto;
    margin-top: 20px;
}
.product__name {
    font-size: 30px !important;
}
.white-color-bg.block--highlighted {
    background-color: #ffffff;
}
.footer-newsletter__social-links {
    margin-left: -9px;
}
.cancel-btn-custom {
    margin: auto !important;
}
.footer-logo-img {
    width: 100%;
    height: 100%;
}

.totop__end button {
    position: absolute;
    right: 7px;
    bottom: 131px;
}

@media (max-width: 479px) {
    .products-list[data-layout="grid-4-full"] .products-list__item {
        width: 46%;
        margin: 6px 6px;
        float: left;
    }
}

@media (max-width: 1024px) {
    .addresses-list__item {
        margin-left: 10px;
        flex-basis: auto !important;
    }
}

// tangorx NEW CSS END---------------------------------------------------------------------------------------------------------------------------------------------------------------------

